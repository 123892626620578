import { NextPage } from 'next';
import React from 'react';
import { BusinessQualities, DeliveringIndustrySolutions, MainSlider, OurCompanyText, SiteMapSection } from 'containers/main';
import {
  ConstructionGraphBg,
  DeploymentGraphBg,
  EnergyGraphBg,
  EngineeringGraphBg,
  Slide1,
  Slide2,
  Slide3,
  Slide4,
  Slide5,
  WirelessGraphBg,
} from 'images/main';
import {
  ConfidenceIcon,
  ExpertiseIcon,
  FlexibilityIcon,
  InnovationIcon,
  IntegrityIcon,
  QualityIcon,
  SafetyIcon,
} from 'svgs/main';

const Main: NextPage = () => {
  return (
    <>
      <MainSlider
        sliderList={[
          {
            bgImage: Slide2,
            title: 'Engineering',
            text1: 'Making any project a success.',
            text2: 'We can make any concept come to life - from scratch.',
            text3: 'Our experts are there every step of the way with both aesthetics and functionality in mind.',
            href: '/expertise/engineering',
            bgGraphic: EngineeringGraphBg,
          },

          {
            bgImage: Slide1,
            title: 'Construction',
            text1: 'Building a better tomorrow.',
            text2: 'We are committed to making a real impact.',
            text3: 'Our crew is on a mission to assemble the infrastructure your community deserves.',
            href: '/expertise/construction',
            bgGraphic: ConstructionGraphBg,
          },
          {
            bgImage: Slide3,
            title: 'Wireless',
            text1: 'Bringing people together.',
            text2: 'We have what it takes to facilitate rock-solid broadband connections.',
            text3: 'Our commitment to innovation allows us to deliver cutting-edge network solutions.',
            href: '/expertise/communication/wireless',
            bgGraphic: WirelessGraphBg,
          },
          {
            bgImage: Slide4,
            title: 'Energy',
            text1: 'Keeping the lights on.',
            text2: 'We work tirelessly, fueling your day-to-day energy needs.',
            text3: 'Our experts provide tried-and-true energy services tailored to your requirements.',
            href: '/expertise/energy',
            bgGraphic: EnergyGraphBg,
          },
          {
            bgImage: Slide5,
            title: 'Technology Deployment',
            text1: 'Integrating modern innovations.',
            text2: 'We use avant-garde tech in all our processes.',
            text3: 'Our employees are fully equipped to carry out the installation, maintenance, and repair activities.',
            href: '/expertise/technology-deployment/gpm',
            bgGraphic: DeploymentGraphBg,
          },
        ]}
      />
      <DeliveringIndustrySolutions
        title={'Delivering industry-leading solutions'}
        description={
          'is a full-service company offering infrastructure solutions for the Telecommunications providers, Electric Utilities, Oil & Gas, Wireless Carriers, Engineering, and Technology Deployment industries.'
        }
        orangeText={'Utilities One '}
      />
      <BusinessQualities
        list={[
          {
            icon: <SafetyIcon />,
            title: 'Safety',
            description:
              'Our top priority is always the health and well-being of our staff members and customers.',
          },
          {
            icon: <ConfidenceIcon />,
            title: 'Confidence',
            description:
              'Valuing our employees and customers, we develop strong, trustworthy, and impactful work and business connections.',
          },
          {
            icon: <QualityIcon />,
            title: 'Quality',
            description:
              'We are always aiming for excellence in every project, whether in minor tasks or large-scale projects.',
          },
          {
            icon: <InnovationIcon />,
            title: 'Innovation',
            description:
              'Our belief is rooted in the power of modern solutions and technological advancement to achieve exceptional results.',
          },
          {
            icon: <IntegrityIcon />,
            title: 'Integrity',
            description:
              'Commitment to ethical conduct and transparent operations sets the foundation for trust and reliability in our world-class services.',
          },
          {
            icon: <ExpertiseIcon />,
            title: 'Expertise',
            description:
              'Equipped with the right knowledge and extensive experience we can accomplish even the most challenging situations with confidence and proficiency.',
          },
          {
            icon: <FlexibilityIcon />,
            title: 'Flexibility',
            description:
              'Adapting to the ever-changing environment, we guarantee efficient and flexible business operations.',
          },
        ]}
      />
      <SiteMapSection />
      <OurCompanyText />
    </>
  );
};

export default Main;
