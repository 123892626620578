import React, { FC } from 'react';
import { Theme, Box, Grid, Typography, makeStyles, Container } from '@material-ui/core';
import { AboutUs, Careers, ContactUs, Expertise, News, Projects } from 'images/main';
import Link from 'next/link';
import Image from 'blog/components/Image';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(20),
  },
  card: {
    marginTop: theme.spacing(8),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(10),
    },
  },
  mainTitle: {
    fontWeight: 500,
    color: theme.palette.primary.dark,
  },
  mainTitleBox: {
    marginBottom: theme.spacing(0),
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(0),
    },
  },
  divider: {
    width: 100,
    height: 1,
    backgroundColor: theme.palette.primary.main,
    marginTop: theme.spacing(6),
  },
  title: {
    fontWeight: 500,
    color: theme.palette.primary.dark,
    margin: theme.spacing(4, 0, 2),
  },
  fullDivider: {
    width: '100%',
    height: 1,
    backgroundColor: theme.palette.secondary.light,
    marginBottom: theme.spacing(8),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(10),
    },
  },
  image: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const SiteMapSection: FC = () => {
  const classes = useStyles();
  const data: Array<{ image: string; title: string; description: string; href: string }> = [
    {
      image: AboutUs,
      title: 'About Us',
      description: 'Discover our company\'s legacy, industry insights, and meet our dedicated team led by our visionary CEO.',
      href: '/about-us',
    },
    {
      image: News,
      title: 'News',
      description: 'Get in touch with us. We’re here to guide you to solutions.',
      href: '/news',
    },
    {
      image: Expertise,
      title: 'Expertise',
      description: 'Explore our services and understand the advantages we offer.',
      href: '/expertise',
    },
    {
      image: Projects,
      title: 'Projects',
      description: 'Navigate our case studies and standout achievements.',
      href: '/projects',
    },
    {
      image: Careers,
      title: 'Careers',
      description: 'Explore current opportunities.',
      href: '/careers',
    },
    {
      image: ContactUs,
      title: 'Contact Us',
      description: 'Get in touch with us. We’re here to guide you to solutions.',
      href: '/contact-us',
    },
  ];
  return (
    <Box className={classes.root} id={'site-map'}>
      <Container fixed>
        <Box className={classes.fullDivider} />
        <Box className={classes.mainTitleBox} data-aos="fade-up">
          <Typography variant="h2" className={classes.mainTitle}>
            Site Map
          </Typography>
          <Box className={classes.divider} margin="0 auto" />
        </Box>
        <Grid container justify="center" spacing={5}>
          {data.map((card, index) => (
            <Grid item sm={12} md={6} lg={4} key={index} data-aos="fade-up">
              <Box className={classes.card}>
                <Link href={card.href}>
                  <Image src={card.image} alt="1" width={450} height={300} className={classes.image} />
                </Link>
                <Box className={classes.divider} />
                <Typography variant="h4" className={classes.title}>
                  {card.title}
                </Typography>
                <Typography variant="body1">{card.description}</Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default SiteMapSection;
